@import "../../assets/styles/init";

.container {
  position: relative;
  width: 100%;
  background-color: $secondary-bg;

  &::before {
    @include absolute(-30px, 0, auto, 0);
    content: "";
    width: 100%;
    height: 31px;
    background: url("/nomination-top-border.svg") center no-repeat;
  }

  &Wrap {
    width: 100%;
    max-width: $section-max-width;
    margin: 0 auto;
    padding: 100px $gap6 0;

    @include tablets {
      padding: $gap6 $gap2 0;
    }
  }

  h3 {
    max-width: 430px;
    padding-bottom: $gap6;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
