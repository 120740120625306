/* stylelint-disable */
.architecture {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.arrow {
  display: block;
  width: 1.8em;
  height: 1em;
  fill: #000;
}

.BA-and-PM {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.boldClose {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.clip {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.close {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.design {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.engineering {
  display: block;
  width: 1.02em;
  height: 1em;
  fill: #000;
}

.facebook {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.impact {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.infrastructure-devops {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.innovation {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.it-education {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.management {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.project-management {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.qa {
  display: block;
  width: 1.02em;
  height: 1em;
  fill: #000;
}

.reason-feedback {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.reason-knowledges {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.reason-networking {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.reason-recognition {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.reason-reputation {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.reason-solutions {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.senseit-logo {
  display: block;
  width: 3.59em;
  height: 1em;
  fill: #000;
}

.stage-1-blue {
  display: block;
  width: 0.99em;
  height: 1em;
  fill: #000;
}

.stage-1-green {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.stage-1-orange {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.stage-1-violet {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.stage-2-blue {
  display: block;
  width: 1.04em;
  height: 1em;
  fill: #000;
}

.stage-2-green {
  display: block;
  width: 0.99em;
  height: 1em;
  fill: #000;
}

.stage-2-orange {
  display: block;
  width: 0.7em;
  height: 1em;
  fill: #000;
}

.stage-2-violet {
  display: block;
  width: 0.68em;
  height: 1em;
  fill: #000;
}

.success {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}

.video {
  display: block;
  width: 1em;
  height: 1em;
  fill: #000;
}


/* stylelint-enable */