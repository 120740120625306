@import "helpers/breakpoints";
@import "helpers/extend";

@mixin input($height: 100%) {
  @include font(400, 22px, 24px);

  display: block;
  border: 1px solid $input-border-color;
  background-color: $input-bg;
  width: 100%;
  height: $height;
  color: $primary-text;
  text-transform: uppercase;
  padding: 12px $gap1;
  letter-spacing: inherit;

  @include tablets {
    @include font(400, 18px, 45px);
  }

  @include phones-landscape {
    @include font(400, 13px, 24px);

    padding: 8px $gap1;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 300px $input-bg inset !important;
    -webkit-text-fill-color: $primary-text !important;
  }

  &::placeholder {
    @include font(400, 22px, 24px);

    color: inherit;
    text-transform: uppercase;
    letter-spacing: inherit;

    @include tablets {
      @include font(400, 18px, 24px);
    }

    @include phones-landscape {
      @include font(400, 13px, 24px);
    }
  }

  &:hover,
  &:focus {
    border-color: $primary-text;
    @include transition();
  }

  &:focus {
    outline: none;
    background-color: $input-bg-active;
    color: $primary-text;

    &::placeholder {
      opacity: 0;
    }
  }

  &:disabled {
    background-color: $input-bg-disabled;
    border: 1px solid $input-bg-disabled;
    color: $input-text-disabled;

    &::placeholder {
      color: $input-text-disabled;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

@mixin font($fw, $fs, $lh: $lh-base, $ff: $regular-font) {
  font: $fw #{$fs}/#{$lh} $ff;
}

@mixin text-cut($fontSize, $lines, $lineHeight: 1.2) {
  display: block;
  display: -webkit-box;
  font-size: $fontSize + px;
  line-height: $lineHeight;
  max-width: 100%;
  -webkit-line-clamp: $lines;
  height: ($fontSize * $lineHeight * $lines) + px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  margin: auto;
}

@mixin transition($prop: all, $duration: 0.25s, $easing: ease-out) {
  transition-duration: $duration;
  transition-property: $prop;
  transition-timing-function: $easing;
}

@mixin link-custom-hover(
  $bottom: 0,
  $hover-height: 100%,
  $hover: $black,
  $color: inherit
) {
  display: inline-flex;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: $color;
  cursor: pointer;

  &::before {
    @include absolute(auto, -1px, $bottom, -1px);

    content: "";
    display: block;
    height: 1px;
    background-color: $black;
  }

  &:hover {
    @include transition(all, 0.15s, linear);
    text-decoration: none;
    color: $white;

    &::before {
      @include transition(height, 0.15s, ease);
      height: $hover-height;
      background-color: $black;
      z-index: -1;
    }
  }
}
