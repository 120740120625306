@import "../mixins/mixins";

// Colors
$white: #fff;
$black: #000;

$default-text: #000;
$primary-text: #fff;
$secondary-text: #8d8d8d;
$active-text: #d775ff;
$active-text-btn: #a21ed8;

$default-bg: #fff;
$primary-bg: #a21ed8;
$primary-bg-dark: #520f6e;
$secondary-bg: #f0f0f0;
$active-bg: #f1672e;

$form-error-color: #ff8a00;

$default-br-color: #c4c4c4;
$primary-br-color: #ce68f8;
$secondary-br-color: #000;
$input-br-color: #d87cfd;

// Fonts
$regular-font: "Manrope", sans-serif;
$system-fonts-list: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

// Line Height
$lh-base: 16px;

// Font size
$fs-base: 16px !default;

// Paddings and margins
$gap1: 10px;
$gap2: 20px;
$gap3: 30px;
$gap4: 40px;
$gap5: 50px;
$gap6: 60px;

//gallery

$img-width: 264px;
$img-height: 165px;

$img-width-mob: 160px;
$img-height-mob: 100px;

$video-width: 528px;
$video-height: 330px;

//sections

$section-max-width: 1440px;

//form

$app-input-height: 62px;
$app-input-width-half: calc(50% - 25px);
